// Import stimulus built in things
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// Import stimulus libraries
// import Something from "stimulus-something"

const application = Application.start()

// Register stimulus libraries
// application.register("something", Something)

// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

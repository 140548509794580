import { Controller } from "stimulus"
import _ from "lodash";

export default class ApplicationController extends Controller {
  findController(id, controllerPath) {
    return this.application.getControllerForElementAndIdentifier(document.getElementById(id), controllerPath)
  }

  reflectValue(event) {
    this[this.previewTargets(event)].forEach(previewTarget => {
      previewTarget.textContent = event.target.value
    })
  }

  targetPrefix(event, idSuffix = '') {
    const originalId = event.currentTarget?.id || event.target.id
    let idRoot = _.camelCase(originalId)

    if (idSuffix) {
      idRoot = idRoot.substring(0, idRoot.indexOf(_.upperFirst(_.camelCase(idSuffix))))
    }

    return idRoot
  }

  previewTarget(event, idSuffix = '') {
    return `${this.targetPrefix(event, idSuffix)}PreviewTarget`
  }

  previewTargets(event, idSuffix = '') {
    return `${this.previewTarget(event, idSuffix)}s`
  }

  hiddenRemoveTarget(event, idSuffix = '') {
    return `${this.targetPrefix(event, idSuffix)}HiddenRemoveTarget`
  }

  uploadFieldTarget(event, idSuffix = '') {
    return `${this.targetPrefix(event, idSuffix)}UploadFieldTarget`
  }
}

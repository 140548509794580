import Rails from "@rails/ujs"

export default class extends ApplicationController {
  static targets = [
    "btnAddSchedule", "btnHideAddScheduleModal", "formAddSchedule", "flatpickrElement", "articles"
  ]

  get flashMessagesController() {
    return this.findController("flash-messages", "components--flash-messages")
  }

  clearSelectedDate() {
    const currentController = this

    currentController.flatpickrElementTargets.forEach(flatpickrElementTarget => {
      currentController.findController(flatpickrElementTarget.id, "components--flatpickr").clear()
    })
  }

  addSchedule(event) {
    event.preventDefault()

    const currentController = this
    let formData = new FormData(currentController.formAddScheduleTarget)

    Rails.ajax({
      type: "POST",
      url: "/realtor_dashboard/events",
      data: formData,
      dataType: "json",
      success: function(notice) {
        currentController.formAddScheduleTarget.reset()
        currentController.clearSelectedDate()
        currentController.btnHideAddScheduleModalTarget.click()
        currentController.flashMessagesController.flashesValue = [['notice', notice]]
        currentController.flashMessagesController.showFlashMessages()

        window.setTimeout(function(){
          location.reload()
        },1000)
      },
      error: function (errors) {
        currentController.flashMessagesController.flashesValue = [['error', errors]]
        currentController.flashMessagesController.showFlashMessages()
      }
    })
  }

  toggleArticles() {
    this.articlesTarget.classList.toggle('d-none')
  }
}

import Rails from "@rails/ujs"
import _ from "lodash"

export default class extends ApplicationController {
  static targets = [
    "userAvatarUploadField", "userCompanyLogoUploadField", "userCoverImageUploadField",
    "imagePlaceHolder",
    "userAvatarHiddenRemove", "userCompanyLogoHiddenRemove", "userCoverImageHiddenRemove",
    "userAvatarPreview", "userCompanyLogoPreview", "userCoverImagePreview",
    "userNamePreview", "userCompanyNamePreview", "userPhoneNumberPreview", "userWebsitePreview", "userLocationPreview",
    "userAvatarOld", "userCompanyLogoOld", "userCoverImageOld",
    "userAvatarColUpload", "userCompanyLogoColUpload", "userCoverImageColUpload",
    "userCompanyLogoLightGallery", "userCoverImageLightGallery", "userAvatarLightGallery",
    "userProvince", "userFirstName", "userLastName"
  ]

  isValidFileType(fileUrl, fileType) {
    const extensionLists = {
      image: ['png', 'jpg', 'jpeg', 'gif']
    }
    const fileExtension = _.lowerCase(_.last(fileUrl.split('.')))

    return extensionLists[fileType].includes(fileExtension)
  }

  uploadImage(event) {
    const newFile = event.target.files[0]
    let currentController = this
    let oldTarget = currentController[`${currentController.targetPrefix(event, '')}OldTarget`]

    if (!newFile || !this.isValidFileType(event.target.value, 'image')) {
      if (oldTarget.hasChildNodes()) {
        currentController[`${currentController.targetPrefix(event)}ColUploadTarget`].replaceChild(oldTarget.firstChild, event.target)
        oldTarget.innerHTML = ''
      } else {
        currentController.clearInputFile(event.target)
      }

      return false
    }

    const fileReader = new FileReader()

    fileReader.onload = function () {
      currentController[currentController.previewTargets(event)].forEach(previewTarget => {
        previewTarget.src = this.result
      })

      currentController.refreshLightGallery(currentController.targetPrefix(event))

      currentController[currentController.hiddenRemoveTarget(event)].value = false
    }

    oldTarget.innerHTML = ''
    oldTarget.appendChild(event.target.cloneNode(true))
    fileReader.readAsDataURL(newFile)
  }

  clearInputFile(uploadField) {
    if (!uploadField.value) {
      return false
    }

    // for IE11, latest Chrome/Firefox/Opera...
    try {
      uploadField.value = ''
    } catch (err) {
      console.log(err)
    }

    // for IE5 ~ IE10
    if (uploadField.value) {
      const form = document.createElement('form')
      const parentNode = uploadField.parentNode
      const ref = uploadField.nextSibling

      form.appendChild(uploadField)
      form.reset()
      parentNode.insertBefore(uploadField, ref)
    }
  }

  removeImage(event) {
    this[this.previewTargets(event, "remove")].forEach(previewTarget => {
      previewTarget.src = this.imagePlaceHolderTarget.src
    })

    this[this.hiddenRemoveTarget(event, "remove")].value = true
    this.clearInputFile(this[this.uploadFieldTarget(event, "remove")])

    this.refreshLightGallery(this.targetPrefix(event, "remove"))
  }

  refreshLightGallery(targetPrefix) {
    const currentController = this

    this[`${targetPrefix}LightGalleryTargets`].forEach(lightGalleryTarget => {
      currentController.findController(lightGalleryTarget.id, "components--light-gallery").refresh()
    })
  }

  provincesByCountry(event) {
    const currentController = this

    Rails.ajax({
      type: "GET",
      url: "/realtor_dashboard/realtor/basic_information/provinces",
      data: new URLSearchParams({
        country_key: event.target.value
      }),
      dataType: "json",
      success: function(data) {
        let userProvinceTarget = currentController.userProvinceTarget
        let { provinces } = data

        userProvinceTarget.options.length = 0
        provinces.unshift(["", ""])

        provinces.map(item => new Option(item[0], item[1])).forEach(child => userProvinceTarget.appendChild(child))
      }
    })
  }

  setName() {
    this.userNamePreviewTargets.forEach(previewTarget => {
      previewTarget.querySelector("span").textContent = `${this.userLastNameTarget.value} ${this.userFirstNameTarget.value}`
    })
  }
}

export default class extends ApplicationController {
  static targets = [
    "userBadge", "userBadgePurple", "userBadgeDarkGreen", "userBadgeLightGreen", "userBadgeBlue"
  ]

  changeBadge(event) {
    this.userBadgeTargets.forEach(userBadgeTarget => {
      userBadgeTarget.classList.add('d-none')
    })

    const userBadgePreviewTargets = this[`${this.targetPrefix(event)}Targets`]
    userBadgePreviewTargets.forEach(userBadgePreviewTarget => {
      userBadgePreviewTarget.classList.remove('d-none')
    })
  }
}

import Swiper from 'swiper/bundle'

export default class extends ApplicationController {
  static targets = [
    "swiper"
  ]

  swiper = new Swiper(this.swiperTarget, {
    slidesPerView: "auto",
    spaceBetween: 5,
    watchOverflow: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1.5,
        spaceBetween: 5
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 5
      }
    }
  })

  update() {
    this.swiper.update()
  }
}

import flatpickr from "flatpickr";

export default class extends ApplicationController {
  static values = {
    type: String,
    dateFormat: String,
    noCalendar: Boolean,
    disabled: Boolean,
    enableTime: Boolean
  }

  static targets = [
    "flatpickrElement"
  ]

  get flatpickrInstance() {
    return this._flatpickrInstance
  }

  set flatpickrInstance(newData) {
    this._flatpickrInstance = newData
  }

  connect() {
    this.loadConfigBasedOnPickerType()

   this.flatpickrInstance = flatpickr(this.flatpickrElementTarget, {
      wrap: true,
      minDate: "today",
      enableTime: this.enableTimeValue,
      noCalendar: this.noCalendarValue,
      dateFormat: this.dateFormatValue,
      disableMobile: true
    })

    if (this.hasDisabledValue) {
      this.flatpickrInstance._input.disabled = true
    }
  }

  loadConfigBasedOnPickerType() {
    switch(this.typeValue) {
      case "time":
        this.dateFormatValue = "H:i"
        this.noCalendarValue = true
        this.enableTimeValue = true
        break
      case "date":
        this.dateFormatValue = "d-m-Y"
        this.noCalendarValue = false
        this.enableTimeValue = false
        break
    }
  }

  clear() {
    this.flatpickrInstance.clear()
  }
}

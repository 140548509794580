import Rails from "@rails/ujs"

export default class extends ApplicationController {
  static targets = [
    "userAboutPreview", "specialitiesSelectedWrapperPreview", "clearAllSpecialities",
    "newTestimonialFieldset", "idTestimonialMaxHidden",
    "userTestimonialClientName", "userTestimonialContent", "testimonials", "componentSwiper",
    "userTestimonialPreview", "userTestimonialHiddenRemove", "previewTestimonialsWrapper"
  ]

  static values = {
    idTestimonialMax: Number
  }

  get modalController() {
    return this.findController("modal-testimonial", "components--modal")
  }

  get flashMessagesController() {
    return this.findController("flash-messages", "components--flash-messages")
  }

  refreshSpecialities(specialities) {
    const currentController = this

    currentController.specialitiesSelectedWrapperPreviewTarget.innerHTML = ""

    specialities.forEach(speciality => {
      let newSpecialitySelected = document.createElement("div")

      newSpecialitySelected.className = "tag-item"
      newSpecialitySelected.innerHTML = speciality.text

      currentController.specialitiesSelectedWrapperPreviewTarget.appendChild(newSpecialitySelected)
    })
  }

  newTestimonialModal(event) {
    if (this.previewTestimonialsWrapperTarget.childElementCount === 6) {
      this.flashMessagesController.flashesValue = [["error", I18n.errors.testimonials_too_many]]
      this.flashMessagesController.showFlashMessages()

      return
    }

    this.toggleNewTestimonialModal(event)
    this.userTestimonialClientNameTarget.focus()
  }

  toggleNewTestimonialModal(event) {
    event.preventDefault()

    this.modalController.toggleModal()
    this.newTestimonialFieldsetTarget.toggleAttribute('disabled')
  }

  updateSwipers() {
    const currentController = this

    currentController.componentSwiperTargets.forEach(componentSwiperTarget => {
      currentController.findController(componentSwiperTarget.id, "components--swiper").update()
    })
  }

  addTestimonial(event) {
    let currentController = this
    this.idTestimonialMaxValue = this.idTestimonialMaxValue + 1
    this.idTestimonialMaxHiddenTarget.value = this.idTestimonialMaxValue

    let formData = new FormData()
    formData.append('client_name', currentController.userTestimonialClientNameTarget.value)
    formData.append('client_type', document.querySelector('input[name="user[testimonials_attributes][0][client_type]"]:checked').value)
    formData.append('content', currentController.userTestimonialContentTarget.value)
    formData.append('id_testimonial_max', this.idTestimonialMaxHiddenTarget.value)

    Rails.ajax({
      type: "post",
      url: "/realtor_dashboard/realtor/detailed_expertise_infos/testimonials",
      data: formData,
      dataType: "json",
      success: function(data) {
        if (data.errors) {
          currentController.flashMessagesController.flashesValue = data.errors
          currentController.flashMessagesController.showFlashMessages()
        } else {
          currentController.testimonialsTargets.forEach(testimonialsTarget => {
            let newTestimonial = new DOMParser().parseFromString(data.html, 'text/html').body

            if (testimonialsTarget.dataset.notShowHiddenTag === "true") {
              newTestimonial.querySelectorAll(".hidden-field").forEach(element => element.remove())
            }

            const childLength = newTestimonial.children.length

            // https://developer.mozilla.org/en-US/docs/Web/API/Node/appendChild
            for (let i = 0; i < childLength; i++) {
              testimonialsTarget.appendChild(newTestimonial.children[0])
            }
          })
          currentController.userTestimonialClientNameTarget.value = ""
          currentController.userTestimonialContentTarget.value = ""
          document.getElementById("user_testimonials_attributes_0_client_type_home_buyer").checked = true

          currentController.toggleNewTestimonialModal(event)

          currentController.updateSwipers()
        }
      }
    })
  }

  removeUserTestimonialPreview(event) {
    const id = event.currentTarget?.dataset?.id || event.target?.dataset?.id

    // remove preview
    this.userTestimonialPreviewTargets.forEach(userTestimonialPreviewTarget => {
      if (userTestimonialPreviewTarget.dataset.id === id) {
        userTestimonialPreviewTarget.remove()
      }
    })

    // set _destroy=true nested_attributes
    this.userTestimonialHiddenRemoveTargets.forEach(userTestimonialHiddenRemoveTarget => {
      if (userTestimonialHiddenRemoveTarget.dataset.id === id) {
        userTestimonialHiddenRemoveTarget.value = true
      }
    })

    this.updateSwipers()
  }
}

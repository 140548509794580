import videojs from "video.js"
import controllerExpose from "../../../../utilities/expose_controller"
import Rails from "@rails/ujs"

export default class extends ApplicationController {
  static targets = ["videoElement", "videoSource", "videoTitle", "videoSourceShowModal", "videoTransformationsWrapper"]
  static values = {
    posterUrl: String,
    videoId: Number
  }

  defaultPlayerOptions = {
    controls: false,
    preferFullWindow: true,
    responsive: true,
    fluid: true,
    muted: true,
    playsinline: true,
    poster: this.posterUrlValue,
    loop: true
  }

  connect() {
    this.initPlayer()
    controllerExpose(this)
  }

  initPlayer() {
    const currentController = this
    const playerOptions = this.defaultPlayerOptions

    return videojs(
      currentController.videoElementTarget,
      playerOptions,
      function () {
        const player = this
        player.addClass("video-js")
        player.addClass("vjs-big-play-centered")
        currentController.element.querySelector(".vjs-big-play-button").classList.add("d-block")
        currentController.element.addEventListener("mouseover", function() {
          player.play()

          if (currentController.element.querySelector(".vjs-big-play-button").classList.value.includes("d-block")) {
            currentController.element.querySelector(".vjs-big-play-button").classList.remove("d-block")
          }
        })
        currentController.element.addEventListener("mouseout", function() {
          player.pause()
          // player.currentTime(0)
          player.hasStarted(false)

          if (!currentController.element.querySelector(".vjs-big-play-button").classList.value.includes("d-block")) {
            currentController.element.querySelector(".vjs-big-play-button").classList.add("d-block")
          }
        })
        player.on("play", function() {
          if (currentController.element.querySelector(".vjs-big-play-button").classList.value.includes("d-block")) {
            currentController.element.querySelector(".vjs-big-play-button").classList.remove("d-block")
          }
        })
        player.on("pause", function() {
          if (!currentController.element.querySelector(".vjs-big-play-button").classList.value.includes("d-block")) {
            currentController.element.querySelector(".vjs-big-play-button").classList.add("d-block")
          }
        })
      }
    )
  }

  showVideoModal() {
    const currentController = this

    Rails.ajax({
      type: "GET",
      url: `/realtor_dashboard/video_categories/${currentController.videoIdValue}`,
      data: new URLSearchParams({
        video_source: currentController.videoSourceTarget.src
      }),
      dataType: "script"
    })
  }
}

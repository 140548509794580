import controllerExpose from "../utilities/expose_controller"

export default class extends ApplicationController {
  static targets = ["videoElement", "videoTitle", "linkDownloadVideo"]

  connect() {
    controllerExpose(this)

    const currentController = this
    const videoModal = $('#video-modal')

    videoModal.on('shown.bs.modal', function() {
      currentController.videoElementTarget.play()
    })

    videoModal.on('hide.bs.modal', function() {
      currentController.videoElementTarget.pause()
    })
  }

  showVideoModal(videoTitle, videoSource) {
    this.videoTitleTarget.textContent = videoTitle
    this.videoElementTarget.src = videoSource
  }
}

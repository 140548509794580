import Rails from "@rails/ujs"
import SlimSelect from 'slim-select'
import _ from "lodash";

export default class extends ApplicationController {
  static targets = [
    "specialitiesSelect", "optionSelected"
  ]

  static values = {
    specialitiesSelected: Array,
    specialityValuesSelected: Array
  }

  initialize() {
    const currentController = this

    if (this.hasSpecialitiesSelectTarget) {
      const specialitiesSelect = new SlimSelect({
        select: this.specialitiesSelectTarget,
        hideSelectedOption: true,
        placeholder: I18n.activerecord.attributes.user.specialities,
        searchingText: I18n.common.select_tag.searching_text,
        searchText: I18n.common.select_tag.search_text,
        searchPlaceholder: I18n.common.select_tag.search_placeholder,
        allowDeselectOption: "",
        ajax: function (search, callback) {
          if (search.length < 0) {
            return
          }

          Rails.ajax({
            type: "get",
            url: "/realtor_dashboard/realtor/detailed_expertise_infos/specialities",
            data: new URLSearchParams({
              q: search,
              selected: this.selected()
            }),
            dataType: "json",
            success: function(data) {
              let specialities = []

              for (let i = 0; i < data.specialities.length; i++) {
                specialities.push({ text: data.specialities[i].text, value: data.specialities[i].value })
              }
              callback(specialities)
            }
          })
        },
        onChange: (info) => {
          currentController.detailedExpertiseInfosController.refreshSpecialities(info)

          if (_.isEmpty(info)) {
            currentController.optionSelectedTarget.disabled = false
          } else {
            currentController.optionSelectedTarget.disabled = true
          }
        }
      })

      specialitiesSelect.setData(this.specialitiesSelectedValue)
      specialitiesSelect.set(this.specialityValuesSelectedValue)
    }
  }

  get detailedExpertiseInfosController() {
    return this.findController("detailed-expertise-infos", "dashboard--realtor-dashboard--realtor--wizards--detailed-expertise-infos--index")
  }
}

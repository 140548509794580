import lightGallery from "lightgallery"
import lgZoom from "lightgallery/plugins/zoom"
import lgRotate from "lightgallery/plugins/rotate"

export default class extends ApplicationController {
  static targets = [
    "lightGallery"
  ]

  lightGallery = lightGallery(this.lightGalleryTarget, {
    selector: "this",
    plugins: [lgZoom, lgRotate],
    showZoomInOutIcons: true,
    actualSize: false,
    flipHorizontal: true,
    counter: false,
    liveRefresh: true
  })

  refresh() {
    this.lightGallery.refresh()
  }
}

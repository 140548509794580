import "datatables.net-dt"
import "datatables.net-dt/css/jquery.dataTables.css"

export default class extends ApplicationController {
  static values = {
    language: String
  }

  connect() {
    const currentController = this

    $(".data-table").DataTable({
      "language": {
        "url": `//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/${currentController.languageValue}.json`
      }
    })
  }
}

export default class extends ApplicationController {
  static targets = ["avatarUrl", "name", "phoneNumber", "companyName"]

  user = {
    avatarUrl: "",
    name: "",
    phoneNumber: "",
    companyName: ""
  }

  initialize() {
    this.setDefaultUserDataInput()
    this.updatePlayerUserValue(this.user)
  }

  setDefaultUserDataInput() {
    this.user = {
      ...this.user,
      avatarUrl: this.avatarUrlTarget.value,
      name: this.nameTarget.value,
      phoneNumber: this.phoneNumberTarget.value,
      companyName: this.companyNameTarget.value
    }
  }

  updatePlayerUserValue(user) {
    const videoWrapper = this.element.querySelector(".video-wrapper")
    videoWrapper.dataset["components-VideoPlayerUserValue"] = JSON.stringify(user)
  }

  updateBadge(event) {
    const userAttribute = event.target.dataset["dashboard-RealtorDashboard-RealtorDashboard-IndexTarget"]
    const newValue = event.target.value
    this.user[userAttribute] = newValue
    this.updatePlayerUserValue(this.user)
  }
}

import videojs from "video.js"
import 'src/boverlay/boverlay.min'

export default class extends ApplicationController {
  static targets = ["videoElement"]
  static values = {
    playerOptions: Object,
    user: Object
  }

  defaultPlayerOptions = {
    controls: true,
    preferFullWindow: true,
    responsive: true,
    fluid: true,
    muted: true,
    controlBar: {
      pictureInPictureToggle: false
    },
    playsinline: true
  }

  player = this.initPlayer()

  initialize() {
    this.addBadgeOverlay(this.player)
  }

  topLeftVerticalOverlayBadge(user) {
    return `
      <svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 490 990" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <path d="M344,0 L344,596.868315 C344,609.517514 338.315399,621.496596 328.517041,629.492332 L198.609336,735.519388 C183.120202,748.160204 160.879798,748.160204 145.390664,735.519388 L15.482959,629.492332 C5.68460114,621.496596 0,609.517514 0,596.868315 L0,0 L344,0 Z" id="path-1-7800-502914"></path>
          <path d="M49.4729741,0 L350.527026,0 C354.690416,2.41041934e-14 358.418208,2.57955104 359.885457,6.47583089 L394.90711,99.4758309 C396.853455,104.64435 394.241367,110.412086 389.072848,112.358431 C387.946315,112.782657 386.752442,113 385.548679,113 L14.451321,113 C8.92847346,113 4.45132096,108.522847 4.45132096,103 C4.45132096,101.796237 4.66866351,100.602364 5.09288989,99.4758309 L40.1145431,6.47583089 C41.5817921,2.57955104 45.3095841,4.46263551e-15 49.4729741,0 Z" id="path-3-7800-502914"></path>
          <filter x="-7.6%" y="-22.1%" width="115.3%" height="161.9%" filterUnits="objectBoundingBox" id="filter-5-7800-502914">
            <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
          </filter>
          <path d="M25.638852,-1.03584031e-15 L244.361148,1.03584031e-15 C253.276335,-6.01853049e-16 256.509198,0.928256111 259.768457,2.67132704 C263.027716,4.41439796 265.585602,6.97228371 267.328673,10.231543 C269.071744,13.4908022 270,16.7236646 270,25.638852 L270,244.361148 C270,253.276335 269.071744,256.509198 267.328673,259.768457 C265.585602,263.027716 263.027716,265.585602 259.768457,267.328673 C256.509198,269.071744 253.276335,270 244.361148,270 L25.638852,270 C16.7236646,270 13.4908022,269.071744 10.231543,267.328673 C6.97228371,265.585602 4.41439796,263.027716 2.67132704,259.768457 C0.928256111,256.509198 4.01235366e-16,253.276335 -6.90560205e-16,244.361148 L6.90560205e-16,25.638852 C-4.01235366e-16,16.7236646 0.928256111,13.4908022 2.67132704,10.231543 C4.41439796,6.97228371 6.97228371,4.41439796 10.231543,2.67132704 C13.4908022,0.928256111 16.7236646,6.01853049e-16 25.638852,-1.03584031e-15 Z" id="path-6-7800-502914"></path>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(73.000000, 0.000000)">
            <mask id="mask-2-7800-502914" fill="white">
              <use xlink:href="#path-1-7800-502914"></use>
            </mask>
            <use fill="#19E984" fill-rule="nonzero" xlink:href="#path-1-7800-502914"></use>
            <image mask="url(#mask-2-7800-502914)" x="0" y="0" width="344" height="745" xlink:href="https://img.roomvu.com/img/overlays/overlay6a.png"></image>
            <text mask="url(#mask-2-7800-502914)" font-family="OpenSans-Bold, Open Sans" text-anchor="middle" font-size="24" font-weight="bold" fill="#212121">
              <tspan x="170px" y="569px" alignment-baseline="hanging">${user.companyName}</tspan>
            </text>
            <text mask="url(#mask-2-7800-502914)" font-family="OpenSans-Bold, Open Sans" font-size="32" text-anchor="middle" font-weight="bold" fill="#212121">
              <tspan x="170px" y="496px" alignment-baseline="hanging">${user.phoneNumber}</tspan>
            </text>
          </g>
          <g transform="translate(45.000000, 339.000000)">
            <mask id="mask-4-7800-502914" fill="white">
              <use xlink:href="#path-3-7800-502914"></use>
            </mask>
            <g>
              <use fill="black" fill-opacity="1" filter="url(#filter-5-7800-502914)" xlink:href="#path-3-7800-502914"></use>
              <use fill="#0fdf7a" fill-rule="evenodd" xlink:href="#path-3-7800-502914"></use>
            </g>
            <text mask="url(#mask-4-7800-502914)" font-family="OpenSans-Bold, Open Sans" text-anchor="middle" font-size="32" font-weight="bold" letter-spacing="1" fill="#ffffff">
              <tspan x="200px" y="39px" alignment-baseline="hanging">${user.name}</tspan>
            </text>
          </g>
          <g transform="translate(110.000000, 34.000000)">
            <mask id="mask-7-7800-502914" fill="white">
              <use xlink:href="#path-6-7800-502914"></use>
            </mask>
            <use fill="#FFFFFF" xlink:href="#path-6-7800-502914"></use>
            <image opacity="0.200427827" mask="url(#mask-7-7800-502914)" x="0" y="0" width="270" height="270" xlink:href=""></image>
            <text opacity="0.601236979" mask="url(#mask-7-7800-502914)" font-family="OpenSans-Bold, Open Sans" text-anchor="middle" font-size="32" font-weight="bold" fill="#000000">
              <tspan x="135px" y="118px" alignment-baseline="hanging">
<!--                Your Photo-->
              </tspan>
              <tspan x="135px" y="160px" alignment-baseline="hanging">
<!--                Here-->
              </tspan>
            </text>
            <image preserveAspectRatio="xMinYMin slice" mask="url(#mask-7-7800-502914)" x="0" y="0" width="270" height="270" xlink:href="${user.avatarUrl}"></image>
          </g>
        </g>
      </svg>
    `
  }

  bottomLeftHorizontalOverlayBadge(user) {
    return `
      <svg preserveAspectRatio="xMinYMax meet" viewBox="0 0 790 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <rect id="path-1-9784-242666" x="0" y="0" width="646" height="219" rx="20"></rect>
          <path d="M144.155054,8.76271015 L237.23729,101.844946 C248.920903,113.52856 248.920903,132.47144 237.23729,144.155054 L144.155054,237.23729 C132.47144,248.920903 113.52856,248.920903 101.844946,237.23729 L8.76271015,144.155054 C-2.92090338,132.47144 -2.92090338,113.52856 8.76271015,101.844946 L101.844946,8.76271015 C113.52856,-2.92090338 132.47144,-2.92090338 144.155054,8.76271015 Z" id="path-3-9784-242666"></path>
          <filter x="-14.2%" y="-10.2%" width="128.5%" height="128.5%" filterUnits="objectBoundingBox" id="filter-5-9784-242666">
            <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
          </filter>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(126.000000, 41.000000)">
            <mask id="mask-2-9784-242666" fill="white">
              <use xlink:href="#path-1-9784-242666"></use>
            </mask>
            <use fill="#19E984" xlink:href="#path-1-9784-242666"></use>
            <image mask="url(#mask-2-9784-242666)" x="0" y="0" width="646" height="219" xlink:href="https://img.roomvu.com/img/overlays/overlay6b.png"></image>
            <text mask="url(#mask-2-9784-242666)" font-family="OpenSans-Bold, Open Sans" font-size="34" text-anchor="end" font-weight="bold" letter-spacing="1" fill="#212121">
              <tspan x="580px" y="64px" alignment-baseline="hanging">${user.name}</tspan>
            </text>
            <text mask="url(#mask-2-9784-242666)" font-family="OpenSans-Bold, Open Sans" font-size="32" text-anchor="end" font-weight="bold" fill="#212121">
              <tspan x="580px" y="122px" alignment-baseline="hanging">${user.phoneNumber}</tspan>
            </text>
          </g>
          <g transform="translate(17.000000, 27.000000)">
            <mask id="mask-4-9784-242666" fill="white">
              <use xlink:href="#path-3-9784-242666"></use>
            </mask>
            <g>
              <use fill="black" fill-opacity="1" filter="url(#filter-5-9784-242666)" xlink:href="#path-3-9784-242666"></use>
              <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-3-9784-242666"></use>
            </g>
            <image opacity="0.200427827" mask="url(#mask-4-9784-242666)" x="71" y="71" width="104" height="104" xlink:href=""></image>
            <text opacity="0.601236979" mask="url(#mask-4-9784-242666)" font-family="OpenSans-Bold, Open Sans" text-anchor="middle" font-size="32" font-weight="bold" fill="#000000">
              <tspan x="125px" y="98px" alignment-baseline="hanging">
<!--                Your Photo-->
              </tspan>
              <tspan x="125px" y="140px" alignment-baseline="hanging">
<!--                Here-->
              </tspan>
            </text>
            <image id="avatar-image" preserveAspectRatio="xMinYMin slice" mask="url(#mask-4-9784-242666)" x="0" y="0" width="246" height="246" xlink:href="${user.avatarUrl}"></image>
          </g>
        </g>
      </svg>
    `
  }

  topRightVerticalOverlayBadge(user) {
    return `
      <svg preserveAspectRatio="xMaxYMin meet" viewBox="0 0 490 990" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <path d="M344,0 L344,596.868315 C344,609.517514 338.315399,621.496596 328.517041,629.492332 L198.609336,735.519388 C183.120202,748.160204 160.879798,748.160204 145.390664,735.519388 L15.482959,629.492332 C5.68460114,621.496596 0,609.517514 0,596.868315 L0,0 L344,0 Z" id="path-1-7800-631538"></path>
          <path d="M49.4729741,0 L350.527026,0 C354.690416,2.41041934e-14 358.418208,2.57955104 359.885457,6.47583089 L394.90711,99.4758309 C396.853455,104.64435 394.241367,110.412086 389.072848,112.358431 C387.946315,112.782657 386.752442,113 385.548679,113 L14.451321,113 C8.92847346,113 4.45132096,108.522847 4.45132096,103 C4.45132096,101.796237 4.66866351,100.602364 5.09288989,99.4758309 L40.1145431,6.47583089 C41.5817921,2.57955104 45.3095841,4.46263551e-15 49.4729741,0 Z" id="path-3-7800-631538"></path>
          <filter x="-7.6%" y="-22.1%" width="115.3%" height="161.9%" filterUnits="objectBoundingBox" id="filter-5-7800-631538">
            <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
          </filter>
          <path d="M25.638852,-1.03584031e-15 L244.361148,1.03584031e-15 C253.276335,-6.01853049e-16 256.509198,0.928256111 259.768457,2.67132704 C263.027716,4.41439796 265.585602,6.97228371 267.328673,10.231543 C269.071744,13.4908022 270,16.7236646 270,25.638852 L270,244.361148 C270,253.276335 269.071744,256.509198 267.328673,259.768457 C265.585602,263.027716 263.027716,265.585602 259.768457,267.328673 C256.509198,269.071744 253.276335,270 244.361148,270 L25.638852,270 C16.7236646,270 13.4908022,269.071744 10.231543,267.328673 C6.97228371,265.585602 4.41439796,263.027716 2.67132704,259.768457 C0.928256111,256.509198 4.01235366e-16,253.276335 -6.90560205e-16,244.361148 L6.90560205e-16,25.638852 C-4.01235366e-16,16.7236646 0.928256111,13.4908022 2.67132704,10.231543 C4.41439796,6.97228371 6.97228371,4.41439796 10.231543,2.67132704 C13.4908022,0.928256111 16.7236646,6.01853049e-16 25.638852,-1.03584031e-15 Z" id="path-6-7800-631538"></path>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(73.000000, 0.000000)">
            <mask id="mask-2-7800-631538" fill="white">
              <use xlink:href="#path-1-7800-631538"></use>
            </mask>
            <use fill="#19E984" fill-rule="nonzero" xlink:href="#path-1-7800-631538"></use>
            <image mask="url(#mask-2-7800-631538)" x="0" y="0" width="344" height="745" xlink:href="https://img.roomvu.com/img/overlays/overlay6a.png"></image>
            <text mask="url(#mask-2-7800-631538)" font-family="OpenSans-Bold, Open Sans" text-anchor="middle" font-size="24" font-weight="bold" fill="#212121">
              <tspan x="170px" y="569px" alignment-baseline="hanging">${user.companyName}</tspan>
            </text>
            <text mask="url(#mask-2-7800-631538)" font-family="OpenSans-Bold, Open Sans" font-size="32" text-anchor="middle" font-weight="bold" fill="#212121">
              <tspan x="170px" y="496px" alignment-baseline="hanging">${user.phoneNumber}</tspan>
            </text>
          </g>
          <g transform="translate(45.000000, 339.000000)">
            <mask id="mask-4-7800-631538" fill="white">
              <use xlink:href="#path-3-7800-631538"></use>
            </mask>
            <g>
              <use fill="black" fill-opacity="1" filter="url(#filter-5-7800-631538)" xlink:href="#path-3-7800-631538"></use>
              <use fill="#0fdf7a" fill-rule="evenodd" xlink:href="#path-3-7800-631538"></use>
            </g>
            <text mask="url(#mask-4-7800-631538)" font-family="OpenSans-Bold, Open Sans" text-anchor="middle" font-size="32" font-weight="bold" letter-spacing="1" fill="#ffffff">
              <tspan x="200px" y="39px" alignment-baseline="hanging">${user.name}</tspan>
            </text>
          </g>
          <g transform="translate(110.000000, 34.000000)">
            <mask id="mask-7-7800-631538" fill="white">
              <use xlink:href="#path-6-7800-631538"></use>
            </mask>
            <use fill="#FFFFFF" xlink:href="#path-6-7800-631538"></use>
            <image opacity="0.200427827" mask="url(#mask-7-7800-631538)" x="0" y="0" width="270" height="270" xlink:href=""></image>
            <text opacity="0.601236979" mask="url(#mask-7-7800-631538)" font-family="OpenSans-Bold, Open Sans" text-anchor="middle" font-size="32" font-weight="bold" fill="#000000">
              <tspan x="135px" y="118px" alignment-baseline="hanging">
<!--                Your Photo-->
              </tspan>
              <tspan x="135px" y="160px" alignment-baseline="hanging">
<!--                Here-->
              </tspan>
            </text>
            <image preserveAspectRatio="xMinYMin slice" mask="url(#mask-7-7800-631538)" x="0" y="0" width="270" height="270" xlink:href="${user.avatarUrl}"></image>
          </g>
        </g>
      </svg>
    `
  }

  addBadgeOverlay(player) {
    player.overlay(
      {
        overlays: [
          {
            align: 'top-left',
            showBackground: false,
            class: 'clickthruable w-25 h-50',
            content: this.topLeftVerticalOverlayBadge(this.userValue),
            start: 2,
            end: 5
          },
          {
            align: 'bottom-left',
            showBackground: false,
            class: 'clickthruable w-50 h-25',
            content: this.bottomLeftHorizontalOverlayBadge(this.userValue),
            start: 6,
            end: 10
          },
          {
            align: 'top-right',
            showBackground: false,
            class: 'clickthruable w-25 h-50',
            content: this.topRightVerticalOverlayBadge(this.userValue),
            start: 0,
            end: 0
          }
        ]
      }
    )
  }

  userValueChanged() {
    this.player.overlays_[0].el_.innerHTML = this.topLeftVerticalOverlayBadge(this.userValue)
    this.player.overlays_[1].el_.innerHTML = this.bottomLeftHorizontalOverlayBadge(this.userValue)
    this.player.overlays_[2].el_.innerHTML = this.topRightVerticalOverlayBadge(this.userValue)
  }

  // "Fix" autoplay on iOS devices when the low power mode is turned on
  handleLowPowerModeAutoplay(videoPlayer) {
    // Can't catch event with vanilla javascript
    $("body").on("click touchstart", function () {
      if (!videoPlayer.playing) {
        videoPlayer.play()
      }
    })
  }

  handleNormalAutoplay(videoPlayer) {
    const bigPlayButton = videoPlayer.getChild("bigPlayButton")

    if (bigPlayButton) {
      bigPlayButton.hide()

      videoPlayer.ready(function () {
          const promise = videoPlayer.play()

          if (promise === undefined) {
            bigPlayButton.show()
          } else {
            promise.then(function () {
                bigPlayButton.show()
              }, function () {
                bigPlayButton.show()
              }
            )
          }
        }
      )
    }
  }

  handleAutoplay(videoPlayer, currentController) {
    // currentController.handleLowPowerModeAutoplay(videoPlayer) // Cause play / pause error
    currentController.handleNormalAutoplay(videoPlayer)
  }

  initPlayer() {
    const currentController = this
    const playerOptions = this.hasPlayerOptionsValue ? this.playerOptionsValue : this.defaultPlayerOptions

    return videojs(
      currentController.videoElementTarget,
      playerOptions,
      function () {
        this.addClass("video-js")
        this.addClass("vjs-big-play-centered")
        currentController.handleAutoplay(this, currentController)
        this.on('loadedmetadata', function () {
            const duration = this.duration()
            this.overlay(
              {
                overlays: [
                  {
                    align: 'top-left',
                    showBackground: false,
                    class: 'clickthruable w-25 h-50',
                    content: currentController.topLeftVerticalOverlayBadge(currentController.userValue),
                    start: 2,
                    end: 7
                  },
                  {
                    align: 'bottom-left',
                    showBackground: false,
                    class: 'clickthruable w-50 h-25',
                    content: currentController.bottomLeftHorizontalOverlayBadge(currentController.userValue),
                    start: 10,
                    end: 15
                  },
                  {
                    align: 'top-right',
                    showBackground: false,
                    class: 'clickthruable w-25 h-50',
                    content: currentController.topRightVerticalOverlayBadge(currentController.userValue),
                    start: duration - 7,
                    end: duration - 2
                  }
                ]
              }
            )
          }
        )
      }
    )
  }
}

import Typed from "typed.js"
import _ from "lodash"

export default class extends ApplicationController {
  static values = {
    typedOptions: Object
  }

  // https://github.com/hotwired/stimulus/pull/350
  get typedOptions() {
    if (_.isEmpty(this.typedOptionsValue)) {
      // Modified options based on bluetec's original options
      // https://github.com/mattboldt/typed.js/tree/v2.0.12#customization
      return {
        stringsElement: ".typed-strings",
        typeSpeed: 100,
        backDelay: 1500,
        loop: true,
        contentType: "html",
      }
    } else {
      return this.typedOptionsValue
    }
  }

  initialize() {
    const typed = new Typed(".typed", this.typedOptions)
  }
}
